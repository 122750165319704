import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { BluePrimaryButton } from "../button";

const Footer = () => {
    return(
        <div className={styles.footer + " bg-black py-10"}>
            <div className={styles.footerwrapper + " w-11/12 container mx-auto"}>
                {/* <div className={styles.footer__header + " flex gap-5 justify-start items-center mb-24"}>
                    <div className={styles.item}>
                        <svg className="w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>
                    </div>
                    <div className={styles.item}>
                        <svg className="w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>                    
                    </div>
                    <div className={styles.item}>
                        <svg className="w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>                    
                    </div>
                    <div className={styles.item}>
                        <svg className="w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>                    
                    </div>
                </div> */}
                <div className={styles.footer__content + " flex max-md:flex-col max-md:gap-5 justify-between items-end"}>
                    <div className={styles.desc + " max-md:border-b max-md:pb-4 w-2/4 max-md:w-full flex flex-col gap-5"}>
                        <div className={styles.logo}>
                            <img src="../../images/footerlogo.png" alt="logo" />
                        </div>
                        <div className={styles.item + " text-white"}>
                            <p>
                                healfit
                            </p>
                        </div>
                        <div className={styles.item + " text-white text-xs"}>
                            <p>
                                Satelec L.L.C. FZ
                            </p>
                        </div>
                        <div className={styles.item + " text-white text-xs"}>
                            <p>
                                610 Clover Bay Tower, Business Bay 
                            </p>
                        </div>
                        <div className={styles.item + " text-white text-xs"}>
                            <p>
                                Dubai, United Arab Emirates
                            </p>
                        </div>
                        <div className={styles.item + " text-white text-xs"}>
                            <p>
                                info@healfit.ae
                            </p>
                        </div>
                        <div className={styles.item + " text-white text-xs"}>
                            <p>
                                +971 44495097
                            </p>
                        </div>
                    </div>
                    <div className="flex max-md:flex-col max-md:w-full w-3/4 max-md:w-full justify-between w-full">
                        <div className={styles.links + " w-1/2 flex flex-col gap-3"}>
                            <Link className="text-white max-md:text-sm" to={"/"}>Home</Link>
                            <Link className="text-white max-md:text-sm" to={"/about"}>About</Link>
                            <Link className="text-white max-md:text-sm" to={"/category"}>Shop</Link>
                            <Link className="text-white max-md:text-sm" to={"/"}>Careers</Link>
                            <Link className="text-white max-md:text-sm" to={"/"}>News</Link>
                        </div>
                        <div className="w-1/2 max-md:w-full max-md:mt-5 mt-auto">
                            <div className="relative mb-4">
                                <input className="bg-[#464646] p-3 w-full text-xs" type="text" placeholder="Enter Email Address "/>
                                <div className="absolute top-0 right-0">
                                    <button className="bg-[#27BDBE] p-3 text-xs text-white">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                            <div className="flex gap-4 justify-between">
                                <div className={styles.links + " flex flex-col gap-3"}>
                                    <Link className="text-white max-md:text-sm" to={"/shipping-delivery"}>SHIPPING & DELIVERY </Link>
                                    <Link className="text-white max-md:text-sm" to={"/privacy"}>Privacy Policy</Link>
                                    <Link className="text-white max-md:text-sm" to={"/conditions"}>Terms and Conditions</Link>
                                </div>
                                <div className={styles.links + " flex flex-col gap-3"}>
                                    <a target="_blank" className="text-white max-md:text-sm" href="https://www.linkedin.com/company/revee/">Linkedin</a>
                                    <a target="_blank" className="text-white max-md:text-sm" href="https://www.instagram.com/revee.uae">Instagram</a>
                                    <Link className="text-white max-md:text-sm" to={"/contact"}>Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={styles.footer__lbottom + " mt-10 pt-10 border-t border-t-[#595959]"}>
                    <div className={styles.logos + " flex max-md:flex-col max-md:gap-10 justify-between items-center"}>
                        <div className={styles.certificate + " flex flex-col gap-5"}>
                            <p className="text-white">
                                Certification 
                            </p>
                            <div className={styles.cwrapper + " flex items-center gap-5"}>
                                <div className={styles.item}>
                                    <img src="../../images/c1.png" alt="image" />
                                </div>
                                <div className={styles.item}>
                                    <img src="../../images/c2.png" alt="image" />
                                </div>
                                <div className={styles.item}>
                                    <img src="../../images/c3.png" alt="image" />
                                </div>
                                <div className={styles.item}>
                                    <img src="../../images/c4.png" alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className={styles.paymethod + " flex flex-col gap-5"}>
                            <p className="text-white">
                                Payment Methodes   
                            </p>
                            <div className={styles.cwrapper + " flex items-center gap-5"}>
                                <div className={styles.item}>
                                    <img src="../../images/v1.png" alt="image" />
                                </div>
                                <div className={styles.item}>
                                    <img src="../../images/v2.png" alt="image" />
                                </div>
                                <div className={styles.item}>
                                    <img src="../../images/v3.png" alt="image" />
                                </div>
                                <div className={styles.item}>
                                    <img src="../../images/v4.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className={styles.terms + " flex justify-between items-center mt-20 max-md:flex-col-reverse max-md:gap-10"}>
                        <div className={styles.items + " gap-5 max-md:gap-1 flex"}>
                            <p className="text-white max-md:text-xs">
                                © 2024 All rights reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;