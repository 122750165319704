import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Hero from "./hero";
import Banner from "../banner";
import { BluePrimaryButton, SecondPrimaryButton } from "../button";
import Category from "./category";
import Slider1 from "../slider/emblaCarousel";
import EmblaCarousel from "../slider/emblaCarousel";
import '../slider/embla.css'
import '../slider/sandbox.css'
import Subscribe from "./subscribe";
import HomeBannerSlider from "../slider/homeBannerSlider";
import FooterBanner from "../banner/footerBanner";
import { Link } from "react-router-dom";
import Typewriter from "../typeWriter";
import { getAllHomeBannerSliders, getAllHomeContents } from "../../api/index";
import { getAllCategories } from "../../api/category";
import { domain } from "../../api/domain";
import { getPopularProducts } from "../../api/shop";
import Loading from "../loading";

const HomePage = () => {

    const OPTIONS = { align: 'start', loop: true }
    const SLIDE_COUNT = 5
    const SLIDES = Array.from(Array(SLIDE_COUNT).keys())


    const [homeContents , setHomeContents] = useState(null);
    const [bannerSlider , setBannerSlider] = useState(null);
    const [categories , setCategories] = useState(null);
    const [isLoading , setIsLoading] = useState(true)
    // const [loading , setLoading] = useState(false);
    // const [loadedImages, setLoadedImages] = useState(0); 

 


    // get data for home contents
    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getAllHomeContents();
            setHomeContents(data.data?.home[0]);
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
    }, []);


    // get data for home banner slider
    useEffect(() => {
        const fetchData = async () => {
        //   setLoading(true);
          try {
            const data = await getAllHomeBannerSliders();
            setBannerSlider(data.data?.middle_slider);
          } catch (error) {
            console.error("Error fetching data:", error);
          } 
        //   finally {
        //     setLoading(false); // Set loading state to false regardless of success or failure
        //   }
        };
    
        fetchData();
    }, []);


    // get data for categories
    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getAllCategories();
            setCategories(data.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          } 
        };
    
        fetchData();
    }, []);



    // useEffect(() => {
    //     if (loading) {
    //         // Disable scrolling
    //         document.body.classList.add("noScroll");
    //     } else {
    //         // Enable scrolling
    //         document.body.classList.remove("noScroll");
    //     }
    // }, [loading]);



    // useEffect(() => {
    //     const handleImageLoad = () => {
    //         setLoadedImages(prevCount => prevCount + 1); // Increment loaded images count
    //     };

    //     const images = document.querySelectorAll('img');
    //     images.forEach(img => {
    //         img.addEventListener('load', handleImageLoad);
    //     });

    //     return () => {
    //         images.forEach(img => {
    //             img.removeEventListener('load', handleImageLoad);
    //         });
    //     };
    // }, []); // Empty dependency array ensures this effect runs only once

    // // Render Loading component until all images are loaded
    // if (loading || loadedImages < bannerSlider?.length) {
    //     return <Loading />;
    // }



    if (isLoading) {
        // Render Loading component until 5 seconds have passed
        return <Loading />;
    }


    return(
        <div className={styles.homepage}>
            


            <Hero data={homeContents} />

            <div className={styles.homepage__benefit + " max-md:py-16 py-20 bg-[#625E5E]"}>
                <div className="flex flex-col justify-center items-center w-11/12 container mx-auto">
                    <p className={styles.title + " leading-[48px] max-md:text-xl text-4xl text-white text-center max-w-screen-md font-extrabold"}>
                        {homeContents?.middle_title}
                    </p>
                    <p className={styles.desc + " leading-[38px] max-md:text-sm text-lg text-white max-md:mt-10 mt-20 text-center font-normal"}>
                        {homeContents?.middle_description}
                    </p>
                </div>
            </div>


            <HomeBannerSlider>
                {bannerSlider?.map((item) => (
                    <Banner src={item?.banner} />
                ))}
            </HomeBannerSlider>

            <div className={styles.homepage__about + " max-md:py-5 py-18"}>
                <div className={styles.rightqote}>
                    <img src="../../images/rightqote.png" alt="vector"/>
                </div>
                <div className={styles.leftqote}>
                    <img src="../../images/leftqote.png" alt="vector"/>
                </div>
                <div className="max-md:pt-20 pt-40 max-md:pb-10 pb-20 w-11/12 container max-w-4xl mx-auto">
                    <p className={styles.desc + " max-md:text-xs text-lg text-center font-normal"}>
                        Postoperative Compression garments play an active role in the final result of a cosmetic surgery and are a key element for the success of the procedure. This explains why you need to wear them in the month after the surgery. Always follow the instructions of your <br/> doctor carefully.
                    </p>
                    <p className={styles.hashtag + " max-md:text-sm text-xl text-center max-md:mt-10 mt-20 font-bold"}>
                        #healfitlove
                    </p>
                </div>
            </div>

            <div className={styles.homepage__info + " max-md:py-16 py-32 bg-[#343434]"}>
                <div className="w-11/12 container mx-auto flex max-md:flex-col-reverse gap-16 items-start">
                    <div className={styles.left + " max-md:w-full w-2/5 max-md:text-center flex flex-col gap-9"}>
                        <p className={styles.title + " max-md:text-lg text-2xl text-white font-bold"}>
                            {homeContents?.up_video_title}
                        </p>
                        <p className={styles.desc + " max-md:text-sm max-md:text-center text-lg text-white font-normal"}>
                            {homeContents?.up_video_description}
                        </p>
                        <div className={styles.button + " max-md:mx-auto"}>
                            <Link to={"/about"}>
                                <BluePrimaryButton>
                                    Read More
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M4.7749 10.6001H17.0249" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.8999 4.4751L17.0249 10.6001L10.8999 16.7251" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </BluePrimaryButton>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.right + " max-md:w-full w-3/5 rounded-3xl max-w-screen-md overflow-hidden"}>
                        {homeContents?.up_video && (
                            <video className="object-center w-full h-full" controls>
                                <source src={String(domain + homeContents.up_video.substring(1))} type="video/mp4" />
                            </video>
                        )}
                    </div>
                </div>
            </div>

            <Banner src={homeContents?.banner} />


            <div className={styles.advice + " w-11/12 mx-auto flex flex-col justify-center items-center mt-16 mb-16 gap-10"}>
                <p className={styles.title + " text-center max-md:text-lg text-4xl font-extrabold"}>
                    MEDICAL TECHNOLOGY AND ELEGANCE
                </p>
                <Link to={"/contact"}>
                    <SecondPrimaryButton>
                        Would you like some advice?
                    </SecondPrimaryButton>
                </Link>
            </div>



            <div className={styles.categories + " w-11/12 container max-w-5xl mx-auto grid grid-cols-2 gap-6 mb-20"}>
                {categories?.map((item) => (
                    <Category category={item?.category} category_title={item?.category_title} description={item?.description} id={item?.id} image={item?.image} slug={item?.slug} />
                ))}
            </div>



            <div className={styles.homepage__info + " max-md:py-16 py-32 bg-[#E5E5E5]"}>
                <div className="w-11/12 container mx-auto flex max-md:flex-col-reverse gap-16 items-center">
                    <div className={styles.left + " max-md:w-full max-md:text-center w-2/5 flex flex-col gap-9"}>
                        <p className={styles.desc + " max-md:text-sm text-lg text-black font-normal"}>
                            {homeContents?.middle_video_description}
                        </p>
                    </div>
                    <div className={styles.right + " max-md:w-full rounded-3xl w-3/5 max-w-screen-md overflow-hidden"}>
                        {homeContents?.middle_video && (
                            <video className="object-center w-full h-full" controls>
                                <source src={String(domain + homeContents.middle_video.substring(1))} type="video/mp4" />
                            </video>
                        )}
                    </div>
                </div>
            </div>


            <div className={styles.popular + " mt-32 w-11/12 container mx-auto"}>
                <div className={styles.title}>
                    <p className="max-md:text-xl text-5xl mb-4 text-center font-black">
                        POPULAR PRODUCTS
                    </p>
                </div>
                <section className="sandbox__carousel max-w-5xl mx-auto">
                    <EmblaCarousel slides={SLIDES} options={OPTIONS} />
                </section>
            </div>



            <Banner src={homeContents?.button_banner} />


            <div className={styles.treatment + " w-11/12 max-w-5xl mx-auto flex flex-col mb-20"}>
                <div className={styles.treatment__header + " flex flex-col gap-5 text-center mt-20"}>
                    <p className={styles.title + " max-md:text-xl text-5xl font-black"}>
                        {homeContents?.button_video_title}
                    </p>
                    <p className={styles.desc + " max-md:text-sm text-lg font-normal mt-3"}>
                        {homeContents?.button_video_description}
                    </p>
                </div>
                <div className={styles.treatment__video + " mt-20 w-full overflow-hidden rounded-3xl"}>
                        {homeContents?.button_video && (
                            <video className="w-full object-cover" controls>
                                <source src={String(domain + homeContents.button_video.substring(1))} type="video/mp4" />
                            </video>
                        )}
                </div>
                <div className={styles.footer + " mt-20 text-center max-w-xl mx-auto"}>
                    <p className={styles.title + " max-md:text-lg text-2xl font-bold"}>
                        Revée “Sun/care” 50+ 
                    </p>
                    <p className={styles.desc + " leading-[38px] max-md:text-sm text-lg mt-4 font-normal"}>
                        The sunscreen completes the range of products developed for a better comfort of the patient and to optimize the result, often starting from specific surgeons’ demands.
                    </p>
                </div>
            </div>



            <div className={styles.blogs + " container max-w-5xl w-11/12 mx-auto flex flex-col gap-3 mb-20"}>


                <div className="grid grid-cols-2 gap-3">
                    <Link to={"/blog/" + "slug"}>
                        <div className={styles.indexBlogItem + " h-[500px] bg-[#343434] p-10"}>
                            <div className={styles.title}>
                                <p className="text-white font-bold text-4xl">
                                    Have you 
                                    ever change your 
                                    perspective ?
                                </p>
                            </div>
                            <div className={styles.desc + " mt-6"}>
                                <p className="text-white w-8/12">
                                    Highest quality sunscreen products specific men and women  
                                </p>
                            </div>
                            <div className="mt-8">
                                <button className="flex items-center gap-3 bg-[#515151] py-1 px-2 text-sm text-white">
                                    Reed More  
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M4.7749 10.6001H17.0249" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.8999 4.4751L17.0249 10.6001L10.8999 16.7251" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>   
                                </button>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/blog/" + "slug"}>
                        <div className={styles.indexBlogItem + " h-[500px] bg-[#27BDBE] p-10"}>
                            <div className={styles.title}>
                                <p className="text-white font-bold text-4xl">
                                    Have you 
                                    ever change your 
                                    perspective ?
                                </p>
                            </div>
                            <div className={styles.desc + " mt-6"}>
                                <p className="text-white w-8/12">
                                    Highest quality sunscreen products specific men and women  
                                </p>
                            </div>
                            <div className="mt-8">
                                <button className="flex items-center gap-3 bg-[#515151] py-1 px-2 text-sm text-white">
                                    Reed More  
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M4.7749 10.6001H17.0249" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.8999 4.4751L17.0249 10.6001L10.8999 16.7251" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>   
                                </button>
                            </div>
                        </div>
                    </Link>
                </div>



                <div>
                    <Link to={"/blog/" + "slug"}>
                        <div className="w-full bg-[#343434] p-10 h-[300px] flex items-center">
                            <div className="w-5/12">
                                <div className={styles.title}>
                                    <p className="text-white font-bold text-4xl">
                                        Have you 
                                        ever change your 
                                        perspective ?
                                    </p>
                                </div>
                                <div className="mt-8">
                                    <button className="flex items-center gap-3 bg-[#515151] py-1 px-2 text-sm text-white">
                                        Reed More  
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <path d="M4.7749 10.6001H17.0249" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.8999 4.4751L17.0249 10.6001L10.8999 16.7251" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>   
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>


                <div>
                    <Link to={"/blog/" + "slug"}>
                        <div className="w-full bg-[#27BDBE] h-[300px] flex items-center">
                            <div className="w-5/12 p-10">
                                <div className={styles.title}>
                                    <p className="text-white font-bold text-4xl">
                                        Have you 
                                        ever change your 
                                        perspective ?
                                    </p>
                                </div>
                                <div className="mt-8">
                                    <button className="flex items-center gap-3 bg-[#515151] py-1 px-2 text-sm text-white">
                                        Reed More  
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <path d="M4.7749 10.6001H17.0249" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.8999 4.4751L17.0249 10.6001L10.8999 16.7251" stroke="white" stroke-width="1.07692" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>   
                                    </button>
                                </div>
                            </div>

                            <div className="w-7/12 h-full">
                                <img className="object-cover w-full h-full" src="../../images/blogitemimage.png" alt="image" />
                            </div>
                        </div>
                    </Link>
                </div>

            </div>



            <div className={styles.certifications + " max-md:py-10 py-20 bg-black"}>
                <div className={styles.certifications__wrapper + " w-11/12 container mx-auto"}>
                    <div className={styles.title + " text-white text-center"}>
                        <p className="max-md:text-xl text-3xl font-normal">
                            CERTIFICATIONS
                        </p>
                    </div>
                    <div className={styles.items + " max-md:mt-10 mt-20 flex gap-7 items-center justify-center"}>
                        <div className={styles.item + " max-w-25 overflow-hidden"}>
                            <img className="object-cover w-full" src="../../images/c1.png" alt="image" />
                        </div>
                        <div className={styles.item + " max-w-25 overflow-hidden"}>
                            <img className="object-cover w-full" src="../../images/c2.png" alt="image" />
                        </div>
                        <div className={styles.item + " max-w-25 overflow-hidden"}>
                            <img className="object-cover w-full" src="../../images/c3.png" alt="image" />
                        </div>
                        <div className={styles.item + " max-w-25 overflow-hidden"}>
                            <img className="object-cover w-full" src="../../images/c4.png" alt="image" />
                        </div>
                    </div>
                </div>
            </div>


            <FooterBanner />


            <Subscribe />

        </div>
    )
}

export default HomePage;