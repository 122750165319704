import React, { useState } from "react";
import styles from "./styles.module.scss";
import Banner from "../banner";
import Faq from "../faq";
import Subscribe from "../index/subscribe";
import FooterBanner from "../banner/footerBanner";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import PersonalInformation from "../form/personalInformation";
import PanelAdress from "./panelAdress";
import OrderHistory from "./orderHistory";

const Panel = () => {

    const [panelLevel , setPanelLevel] = useState("info")
    const {signOut} = useAuth();

    const handleSignout = () => {
        signOut();
    }



    return(
        <div className={styles.panel + " mt-20"}>
            <div className={styles.panelwrapper + " mb-20 w-11/12 container mx-auto flex flex-col gap-20"}>
                <p  className={styles.title + " font-bold text-5xl max-md:text-xl max-md:text-center text-left text-center"}>MY ACCOUNT</p>
                <div className="flex max-md:flex-col gap-10">
                    <div className={styles.items + " w-1/4 flex max-md:w-full flex-col gap-4"}>
                        {panelLevel == "info" ?
                            <div className={styles.item + " py-3 cursor-pointer w-full flex gap-2 items-center justify-center bg-black"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10ZM12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16C6.79086 16 5 17.7909 5 20V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V20C3 16.6863 5.68629 14 9 14H15C18.3137 14 21 16.6863 21 20V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V20C19 17.7909 17.2091 16 15 16H9Z" fill="white"/>
                                </svg>
                                <p className="text-white text-center">
                                    Information
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z" fill="white"/>
                                </svg>
                            </div> :
                            <div className={styles.item + " py-3 cursor-pointer w-full flex gap-2 items-center justify-center bg-[#27BDBE]"} onClick={() => setPanelLevel("info")}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10ZM12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16C6.79086 16 5 17.7909 5 20V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V20C3 16.6863 5.68629 14 9 14H15C18.3137 14 21 16.6863 21 20V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V20C19 17.7909 17.2091 16 15 16H9Z" fill="white"/>
                                </svg>
                                <p className="text-white text-center">
                                    Information
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z" fill="white"/>
                                </svg>
                            </div>
                        }
                        {panelLevel == "address" ? 
                            <div className={styles.item + " py-3 cursor-pointer w-full flex gap-3 items-center justify-center bg-black"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M21.4793 9.06266C20.3959 4.23975 16.1876 2.0835 12.5001 2.0835C12.5001 2.0835 12.5001 2.0835 12.4897 2.0835C8.81261 2.0835 4.61469 4.23975 3.52094 9.05225C2.29177 14.4272 5.58344 18.9793 8.56261 21.8543C9.66677 22.9168 11.0834 23.4481 12.5001 23.4481C13.9168 23.4481 15.3334 22.9168 16.4272 21.8543C19.4064 18.9793 22.698 14.4377 21.4793 9.06266ZM15.9168 9.92725L11.7501 14.0939C11.5939 14.2502 11.3959 14.3231 11.198 14.3231C11.0001 14.3231 10.8022 14.2502 10.6459 14.0939L9.08344 12.5314C8.78136 12.2293 8.78136 11.7293 9.08344 11.4272C9.38552 11.1252 9.88552 11.1252 10.1876 11.4272L11.198 12.4377L14.8126 8.82308C15.1147 8.521 15.6147 8.521 15.9168 8.82308C16.2189 9.12516 16.2189 9.62516 15.9168 9.92725Z" fill="white"/>
                                </svg>
                                <p className="text-white text-center">
                                    My address
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z" fill="white"/>
                                </svg>
                            </div> :
                            <div className={styles.item + " py-3 cursor-pointer w-full flex gap-3 items-center justify-center bg-[#27BDBE]"} onClick={() => setPanelLevel("address")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M21.4793 9.06266C20.3959 4.23975 16.1876 2.0835 12.5001 2.0835C12.5001 2.0835 12.5001 2.0835 12.4897 2.0835C8.81261 2.0835 4.61469 4.23975 3.52094 9.05225C2.29177 14.4272 5.58344 18.9793 8.56261 21.8543C9.66677 22.9168 11.0834 23.4481 12.5001 23.4481C13.9168 23.4481 15.3334 22.9168 16.4272 21.8543C19.4064 18.9793 22.698 14.4377 21.4793 9.06266ZM15.9168 9.92725L11.7501 14.0939C11.5939 14.2502 11.3959 14.3231 11.198 14.3231C11.0001 14.3231 10.8022 14.2502 10.6459 14.0939L9.08344 12.5314C8.78136 12.2293 8.78136 11.7293 9.08344 11.4272C9.38552 11.1252 9.88552 11.1252 10.1876 11.4272L11.198 12.4377L14.8126 8.82308C15.1147 8.521 15.6147 8.521 15.9168 8.82308C16.2189 9.12516 16.2189 9.62516 15.9168 9.92725Z" fill="white"/>
                                </svg>
                                <p className="text-white text-center">
                                    My address
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z" fill="white"/>
                                </svg>
                            </div>
                        }
                        {panelLevel == "order" ?
                            <div className={styles.item + " py-3 cursor-pointer w-full flex gap-3 items-center justify-center bg-black"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M13.206 20.7887C13.4922 20.8553 13.5184 21.2302 13.2397 21.3231L11.5939 21.8647C7.45845 23.1981 5.28136 22.0835 3.93761 17.9481L2.60428 13.8335C1.27094 9.69808 2.37511 7.51058 6.51053 6.17725L7.09869 5.98246C7.50156 5.84905 7.89291 6.25286 7.77733 6.6612C7.71391 6.88522 7.65314 7.11974 7.59386 7.36475L6.57303 11.7293C5.42719 16.6356 7.10428 19.3439 12.0105 20.5106L13.206 20.7887Z" fill="white"/>
                                    <path d="M17.8855 3.34353L16.1459 2.93728C12.6668 2.11437 10.5939 2.79145 9.37511 5.31228C9.06261 5.9477 8.81261 6.71853 8.60427 7.60395L7.58344 11.9685C6.56261 16.3227 7.90636 18.4685 12.2501 19.4998L14.0001 19.9165C14.6043 20.0623 15.1668 20.156 15.6876 20.1977C18.9376 20.5102 20.6668 18.9894 21.5418 15.229L22.5626 10.8748C23.5834 6.52062 22.2501 4.36437 17.8855 3.34353ZM15.9272 13.8852C15.8334 14.2394 15.5209 14.4685 15.1668 14.4685C15.1043 14.4685 15.0418 14.4581 14.9689 14.4477L11.9376 13.6769C11.5209 13.5727 11.2709 13.1456 11.3751 12.729C11.4793 12.3123 11.9064 12.0623 12.323 12.1665L15.3543 12.9373C15.7814 13.0415 16.0314 13.4685 15.9272 13.8852ZM18.9793 10.3644C18.8855 10.7185 18.573 10.9477 18.2189 10.9477C18.1564 10.9477 18.0939 10.9373 18.0209 10.9269L12.9689 9.64562C12.5522 9.54145 12.3022 9.11437 12.4064 8.6977C12.5105 8.28103 12.9376 8.03103 13.3543 8.1352L18.4064 9.41645C18.8334 9.5102 19.0834 9.93728 18.9793 10.3644Z" fill="white"/>
                                </svg>
                                <p className="text-white text-center">
                                    Order History
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z" fill="white"/>
                                </svg>
                            </div> : 
                            <div className={styles.item + " py-3 cursor-pointer w-full flex gap-3 items-center justify-center bg-[#27BDBE]"} onClick={() => setPanelLevel("order")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M13.206 20.7887C13.4922 20.8553 13.5184 21.2302 13.2397 21.3231L11.5939 21.8647C7.45845 23.1981 5.28136 22.0835 3.93761 17.9481L2.60428 13.8335C1.27094 9.69808 2.37511 7.51058 6.51053 6.17725L7.09869 5.98246C7.50156 5.84905 7.89291 6.25286 7.77733 6.6612C7.71391 6.88522 7.65314 7.11974 7.59386 7.36475L6.57303 11.7293C5.42719 16.6356 7.10428 19.3439 12.0105 20.5106L13.206 20.7887Z" fill="white"/>
                                    <path d="M17.8855 3.34353L16.1459 2.93728C12.6668 2.11437 10.5939 2.79145 9.37511 5.31228C9.06261 5.9477 8.81261 6.71853 8.60427 7.60395L7.58344 11.9685C6.56261 16.3227 7.90636 18.4685 12.2501 19.4998L14.0001 19.9165C14.6043 20.0623 15.1668 20.156 15.6876 20.1977C18.9376 20.5102 20.6668 18.9894 21.5418 15.229L22.5626 10.8748C23.5834 6.52062 22.2501 4.36437 17.8855 3.34353ZM15.9272 13.8852C15.8334 14.2394 15.5209 14.4685 15.1668 14.4685C15.1043 14.4685 15.0418 14.4581 14.9689 14.4477L11.9376 13.6769C11.5209 13.5727 11.2709 13.1456 11.3751 12.729C11.4793 12.3123 11.9064 12.0623 12.323 12.1665L15.3543 12.9373C15.7814 13.0415 16.0314 13.4685 15.9272 13.8852ZM18.9793 10.3644C18.8855 10.7185 18.573 10.9477 18.2189 10.9477C18.1564 10.9477 18.0939 10.9373 18.0209 10.9269L12.9689 9.64562C12.5522 9.54145 12.3022 9.11437 12.4064 8.6977C12.5105 8.28103 12.9376 8.03103 13.3543 8.1352L18.4064 9.41645C18.8334 9.5102 19.0834 9.93728 18.9793 10.3644Z" fill="white"/>
                                </svg>
                                <p className="text-white text-center">
                                    Order History
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z" fill="white"/>
                                </svg>
                            </div>
                        }
                        <div className={styles.signout + " mt-5 max-md:text-center flex"}>
                            <p className="text-left font-bold text-[#27BDBE] cursor-pointer" onClick={handleSignout}>
                                SignOut
                            </p>
                        </div>
                    </div>
                    <div className={styles.panel__container + " w-3/4 max-md:w-full"}>
                        {panelLevel == "info" ? 
                            <PersonalInformation /> : 
                        panelLevel == "address" ? 
                            <PanelAdress /> :
                        panelLevel == "order" ? 
                            <OrderHistory /> : ""
                        }
                    </div>
                </div>
            </div>


            {/* <FooterBanner />

            <div className="mt-10">
                <Faq />
            </div>

            <Subscribe /> */}
        </div>
    )
}

export default Panel;