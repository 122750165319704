import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { domain } from "../../api/domain";

const RecommendItem = ({image , slug , desc}) => {
    return(
        <div className={styles.shopitem}>
            <Link to={"/shop/" + slug}>
                <div className={styles.shopitem__image + " border w-full overflow-hidden h-full"}>
                    <img className="object-cover w-full h-full" src={domain + image?.substring(1)} alt="image" />
                </div>
            </Link>
            <div className={styles.shopitem__desc + " text-center mt-4"}>
                {/* <p  className="max-md:text-[18px] max-md:leading-3 text-2xl">
                    Male collection
                </p> */}
                <p className="max-md:text-[7px] max-md:leading-3 text-xs">
                    {desc}  
                </p>
            </div>
        </div>
    )
}

export default RecommendItem;