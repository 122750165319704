import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { BluePrimaryButton } from "../button";
import Banner from "../banner";
import Faq from "../faq";
import Subscribe from "../index/subscribe";
import { getAllPersonalInformation } from "../../api/user";
import { getCookie } from "../../api/auth";
import { domain } from "../../api/domain";
import axios from "axios";

const PersonalInformation = () => {

    const token = getCookie('token');
    const [personalInformation, setPersonalInformation] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_name: "",
        trn_number: "",
    });
    const [loading , setLoading] = useState(false);
    const [editable , setEditable] = useState(false);

    // get all user information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAllPersonalInformation();
                setPersonalInformation(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalInformation((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };



    const handleEdit = (e) => {    

        e.preventDefault();


        setLoading(true);
    
        axios.put(domain + "api/accounts/info/" , personalInformation , {
          headers : {
            'Authorization' : 'Bearer ' + token,
          }
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false);
        });
    }
    



    return(
        <div className={styles.formpage}>
            <div className={styles.form + " container mx-auto mb-20"}>
                <div className={styles.edit + " flex items-center gap-1 mb-5 cursor-pointer"} onClick={() => setEditable(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3H12V5H5V19H19V12H21V21H3V3Z" fill="black"/>
                        <path d="M22.4142 6.00015L18 1.58594L8 11.5859V16.0002H12.4142L22.4142 6.00015Z" fill="black"/>
                    </svg>
                    <p className="text-sm">Edit</p>
                </div>
                <div className={styles.formwrapper + " flex flex-col gap-5"}>
                    <div className={styles.form__form}>
                        <form className="flex items-start gap-5 flex-col">
                            <div className={styles.formgroup + " w-full flex items-center gap-5"}>
                                <input
                                    className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest"                                    
                                    type="text"
                                    name="first_name"
                                    value={personalInformation.first_name}
                                    onChange={handleChange}
                                    disabled={!editable}
                                />                            
                            </div>
                            <div className={styles.formgroup + " w-full flex items-center gap-5"}>
                                <input
                                    className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest"
                                    type="text"
                                    name="last_name"
                                    value={personalInformation.last_name}
                                    onChange={handleChange}
                                    disabled={!editable}
                                />                            
                            </div>
                            <div className={styles.formgroup + " w-full flex items-center gap-5"}>
                                <input
                                    className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest"
                                    type="text"
                                    name="email"
                                    value={personalInformation.email}
                                    onChange={handleChange}
                                    disabled={!editable}
                                />                            
                            </div>
                            {personalInformation?.company_name && personalInformation?.company_name != "" ? 
                                <div className={styles.formgroup + " w-full flex items-center gap-5"}>
                                    <input
                                        className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest"
                                        type="text"
                                        name="company_name"
                                        value={personalInformation.company_name}
                                        onChange={handleChange}
                                        disabled={!editable}
                                    />                            
                                </div> : ""
                            }
                            {personalInformation?.trn_number && personalInformation?.trn_number != "" ? 
                                <div className={styles.formgroup + " w-full flex items-center gap-5"}>
                                    <input
                                        className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest"
                                        type="text"
                                        name="trn_number"
                                        value={personalInformation.trn_number}
                                        onChange={handleChange}
                                        disabled={!editable}
                                    />                            
                                </div> : ""
                            }
                        </form>
                    </div>
                    <div className={styles.form__form}>
                        <form className="flex items-start gap-5 flex-col">
                            <div className={styles.formgroup + " w-full flex items-center gap-5"}>
                                <input
                                    className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest"
                                    type="text"
                                    name="phone_number"
                                    value={personalInformation.phone_number}
                                    onChange={handleChange}
                                    disabled={!editable}
                                />                            
                            </div>
                            <div className="flex items-center justify-between w-full">
                                {editable == true ? 
                                    <p className="text-sm text-[#3C3C3C] cursor-pointer" onClick={() => {window.location.reload()}}>CANCEL</p> : ""
                                }
                                <div className={styles.button + " flex ml-auto"} onClick={handleEdit}>
                                    {loading == true? 
                                        <BluePrimaryButton>                                
                                            <svg class="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="transparent" stroke-dasharray="31.42 31.42" stroke-dashoffset="10"></circle>
                                            </svg>
                                            Processing...
                                        </BluePrimaryButton>
                                        :
                                        <BluePrimaryButton>
                                            SAVE
                                        </BluePrimaryButton>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalInformation;