import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import "./gallery.css"
import { domain } from '../../api/domain';


// const images = [
//   {
//     original: gallery1image,
//     thumbnail: gallery1image,
//   },
//   {
//     original: gallery2image,
//     thumbnail: gallery2image,
//   },
//   {
//     original: gallery3image,
//     thumbnail: gallery3image,
//   },
//   {
//     original: gallery4image,
//     thumbnail: gallery4image,
//   },
//   // Add more images as needed
// ];

const MyImageGallery = ({data}) => {


  const [images, setImages] = useState([]);

  useEffect(() => {
    if (Array.isArray(data)) {
        setImages(data.map(item => ({
            original: domain + item?.image,
            thumbnail: domain + item?.image,
        })));
    }
}, [data]);

  return (
    <div>
      {images && (
        <ImageGallery items={images} />
      )}
    </div>
  );
};

export default MyImageGallery;