import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import FooterBanner from "../banner/footerBanner";
import Subscribe from "../index/subscribe";
import Faq from "../faq";
import { BluePrimaryButton } from "../button";
import { Link } from "react-router-dom";
import { getAllAdresses, getAllPersonalInformation } from "../../api/user";
import { getCookie } from "../../api/auth";
import axios from "axios";
import { domain } from "../../api/domain";
import PanelAddAddress from "./panelAddAddress";

const PanelAdress = () => {

    const token = getCookie('token')
    const [addresses , setAddresses] = useState(null);
    const [handleAdd , setHandleAdd] = useState(false);
    const [personalInformation, setPersonalInformation] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "", // Add other fields here as needed
    });




    // get all user information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAllPersonalInformation();
                setPersonalInformation(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);



    // get all user addresses
    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getAllAdresses();
            setAddresses(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
    }, []);


    // function for handle delete address forom address list 
    const handelDeleteAddress = (itemId) => {
        axios.delete(domain + `api/accounts/address/?address_id=${itemId}` , {
            headers : {
                'Authorization' : 'Bearer ' + token,
            }
        })
            .then((response) => {
                console.log("Item deleted successfully");
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error deleting item:", error);
            });
    };



    return(
        <div className={styles.formpage}>
            <div className={styles.form + " container mx-auto mb-20"}>
                <div className={styles.formwrapper + " flex flex-col gap-5"}>
                    <div className={styles.addresses}>
                        <ul className="flex flex-col gap-5">
                            {addresses?.map((item) => (
                                <li>
                                    <div className={styles.wrapper + " rounded-lg p-3 flex flex-col gap-3"}>
                                        <div className={styles.wrapper__header + " flex gap-2 items-center"}>
                                            <p className="font-bold">
                                                {item?.first_name_address}
                                            </p>
                                        </div>
                                        <div className={styles.wrapper__body + " flex flex-col gap-2"}>
                                            <p className="font-bold mb-2 text-sm max-md:text-xs">{personalInformation?.first_name}</p>
                                            <p className="text-sm max-md:text-xs">{item?.address}</p>
                                            <p className="text-sm max-md:text-xs">{item?.city + " , " + item?.emirats + " , " + item?.country}</p>
                                            {/* <p className="text-sm max-md:text-xs">{}</p>
                                            <p className="text-sm max-md:text-xs mb-2">{}</p> */}                                    
                                            <p className="text-sm max-md:text-xs">{item?.additional_information}</p>
                                            <p className="text-sm max-md:text-xs">{item?.phone_number}</p>
                                        </div>
                                        <div className={styles.wrapper__footer + " flex items-center justify-end gap-6"}>
                                            {/* <div className={styles.edit + " flex gap-1 items-center"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                    <path d="M19.25 20.1665H2.75C2.37417 20.1665 2.0625 19.8548 2.0625 19.479C2.0625 19.1032 2.37417 18.7915 2.75 18.7915H19.25C19.6258 18.7915 19.9375 19.1032 19.9375 19.479C19.9375 19.8548 19.6258 20.1665 19.25 20.1665Z" fill="#5F5F5F"/>
                                                    <path d="M17.435 3.18986C15.6567 1.41153 13.915 1.36569 12.0909 3.18986L10.9817 4.29903C10.89 4.39069 10.8534 4.53736 10.89 4.66569C11.5867 7.09486 13.53 9.03819 15.9592 9.73486C15.9959 9.74403 16.0325 9.75319 16.0692 9.75319C16.17 9.75319 16.2617 9.71653 16.335 9.64319L17.435 8.53403C18.3425 7.63569 18.7825 6.76486 18.7825 5.88486C18.7917 4.97736 18.3517 4.09736 17.435 3.18986Z" fill="#5F5F5F"/>
                                                    <path d="M14.3096 10.5692C14.0438 10.4409 13.7871 10.3126 13.5396 10.1659C13.338 10.0467 13.1455 9.9184 12.953 9.7809C12.7971 9.68006 12.6138 9.5334 12.4396 9.38673C12.4213 9.37756 12.3571 9.32256 12.2838 9.24923C11.9813 8.99256 11.6421 8.66256 11.3396 8.2959C11.3121 8.27757 11.2663 8.2134 11.2021 8.1309C11.1105 8.0209 10.9546 7.83756 10.8171 7.62673C10.7071 7.48923 10.5788 7.28756 10.4596 7.0859C10.313 6.8384 10.1846 6.5909 10.0563 6.33423C10.0462 6.31255 10.0362 6.29093 10.0264 6.26936C9.8758 5.93742 9.44168 5.83967 9.18393 6.09743L3.97879 11.3026C3.85962 11.4217 3.74962 11.6509 3.72212 11.8067L3.22712 15.3176C3.13545 15.9409 3.30962 16.5276 3.69462 16.9217C4.02462 17.2426 4.48295 17.4167 4.97795 17.4167C5.08795 17.4167 5.19795 17.4076 5.30795 17.3892L8.82795 16.8942C8.99295 16.8667 9.22212 16.7567 9.33212 16.6376L14.5301 11.4396C14.7897 11.18 14.692 10.734 14.3548 10.5888C14.3398 10.5823 14.3248 10.5758 14.3096 10.5692Z" fill="#5F5F5F"/>
                                                </svg>
                                                <p className="text-xs">Edit</p>
                                            </div> */}
                                            <div className={styles.remove + " flex gap-1 items-center cursor-pointer"} onClick={() => handelDeleteAddress(item?.id)}>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M18.4364 4.57625C17.0277 4.43625 15.6189 4.33125 14.2014 4.2525V4.24375L14.0089 3.10625C13.8777 2.30125 13.6852 1.09375 11.6377 1.09375H9.3452C7.30645 1.09375 7.11395 2.24875 6.97395 3.0975L6.7902 4.2175C5.97645 4.27 5.1627 4.3225 4.34895 4.40125L2.56395 4.57625C2.19645 4.61125 1.93395 4.935 1.96895 5.29375C2.00395 5.6525 2.31895 5.915 2.68645 5.88L4.47145 5.705C9.05645 5.25 13.6764 5.425 18.3139 5.88875C18.3402 5.88875 18.3577 5.88875 18.3839 5.88875C18.7164 5.88875 19.0052 5.635 19.0402 5.29375C19.0665 4.935 18.8039 4.61125 18.4364 4.57625Z" fill="#5F5F5F"/>
                                                    <path d="M16.8258 7.1225C16.6158 6.90375 16.327 6.78125 16.0295 6.78125H4.96954C4.67204 6.78125 4.37454 6.90375 4.17329 7.1225C3.97204 7.34125 3.85829 7.63875 3.87579 7.945L4.41829 16.9225C4.51454 18.2525 4.63704 19.915 7.69079 19.915H13.3083C16.362 19.915 16.4845 18.2613 16.5808 16.9225L17.1233 7.95375C17.1408 7.63875 17.027 7.34125 16.8258 7.1225ZM11.952 15.5313H9.03829C8.67954 15.5313 8.38204 15.2338 8.38204 14.875C8.38204 14.5163 8.67954 14.2188 9.03829 14.2188H11.952C12.3108 14.2188 12.6083 14.5163 12.6083 14.875C12.6083 15.2338 12.3108 15.5313 11.952 15.5313ZM12.687 12.0313H8.31204C7.95329 12.0313 7.65579 11.7338 7.65579 11.375C7.65579 11.0163 7.95329 10.7188 8.31204 10.7188H12.687C13.0458 10.7188 13.3433 11.0163 13.3433 11.375C13.3433 11.7338 13.0458 12.0313 12.687 12.0313Z" fill="#5F5F5F"/>
                                                </svg>           */}
                                                <p className="text-[10px]">Delete</p>                              
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div onClick={() => setHandleAdd(!handleAdd)}>
                        {handleAdd == false ?
                        <div className={styles.addnew + " flex items-center gap-2 cursor-pointer"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                <path d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z" fill="#35383F"/>
                            </svg>
                            <p className="text-xs max-md:text-xs">
                                ADD NEW ADDRESS
                            </p>
                        </div> : ""
                        }
                    </div>
                    {handleAdd == true?
                        <PanelAddAddress /> : ""
                    }
                    
                    {/* <div className={styles.form__form + " mt-10"}>
                        <form className="flex items-start gap-5 flex-col">
                            <div className={styles.button + " flex ml-auto"}>
                                <BluePrimaryButton>
                                    Go to Pay
                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                        <path d="M20.7974 2.77344H10.3698C5.84036 2.77344 3.14014 5.47366 3.14014 10.0031V20.4182C3.14014 24.9601 5.84036 27.6603 10.3698 27.6603H20.7849C25.3143 27.6603 28.0146 24.9601 28.0146 20.4307V10.0031C28.027 5.47366 25.3268 2.77344 20.7974 2.77344ZM23.7091 15.8764L18.3709 21.2146C18.1843 21.4013 17.9478 21.4884 17.7114 21.4884C17.475 21.4884 17.2386 21.4013 17.0519 21.2146C16.691 20.8538 16.691 20.2565 17.0519 19.8956L20.7974 16.1501H8.11751C7.60733 16.1501 7.18425 15.7271 7.18425 15.2169C7.18425 14.7067 7.60733 14.2836 8.11751 14.2836H20.7974L17.0519 10.5381C16.691 10.1773 16.691 9.58 17.0519 9.21914C17.4128 8.85828 18.01 8.85828 18.3709 9.21914L23.7091 14.5574C23.8834 14.7316 23.9829 14.968 23.9829 15.2169C23.9829 15.4657 23.8834 15.7022 23.7091 15.8764Z" fill="white"/>
                                    </svg>
                                </BluePrimaryButton>
                            </div>
                        </form>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default PanelAdress;