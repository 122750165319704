import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Category from "../index/category";
import { getAllCategories } from "../../api/category";
import Loading from "../loading";

const CategoryPage = () => {

    const [categories , setCategories] = useState(null);


    // get data for categories
    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getAllCategories();
            setCategories(data.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
    }, []);

    return(
        <div className={styles.categoryPage + " my-20"}>
            <div className={styles.categoryPage__gradient}></div>
            <div className={styles.categoryPage__wrapper + " max-w-5xl w-11/12 container mx-auto grid grid-cols-2 gap-6 mb-32"}>
                {categories?.map((item) => (
                    <Category category={item?.category} category_title={item?.category_title} description={item?.description} id={item?.id} image={item?.image} slug={item?.slug} />
                ))}
            </div>
        </div>
    )
}


export default CategoryPage;