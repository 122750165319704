import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const PrivateRoute = ({children}) => {
  const { isLoggedIn } = useAuth();

  return (
        isLoggedIn ? children : <Navigate to="/signin" />
  );
};

export default PrivateRoute;