import React from "react";
import styles from "./styles.module.scss";

const Faq = () => {
    return(
        <div className={styles.faq + " max-md:hidden container max-w-6xl mx-auto flex justify-between items-center"}>
            <div className={styles.item + " flex gap-2 max-md:gap-1 items-center"}>
                <svg className="max-md:w-3" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                    <path d="M36.3654 26.2169C36.839 26.2169 37.2111 26.589 37.2111 27.0626V28.754C37.2111 31.5618 34.9446 33.8283 32.1369 33.8283C32.1369 31.0374 29.8535 28.754 27.0626 28.754C24.2718 28.754 21.9884 31.0374 21.9884 33.8283H18.6056C18.6056 31.0374 16.3222 28.754 13.5313 28.754C10.7405 28.754 8.45706 31.0374 8.45706 33.8283C5.64931 33.8283 3.38281 31.5618 3.38281 28.754V25.3712C3.38281 24.4409 4.14395 23.6798 5.07423 23.6798H21.1427C23.4768 23.6798 25.3712 21.7854 25.3712 19.4512V10.1484C25.3712 9.21817 26.1324 8.45703 27.0626 8.45703H28.4834C29.7013 8.45703 30.8176 9.11668 31.4265 10.1654L32.509 12.0597C32.6612 12.3304 32.4583 12.6856 32.1369 12.6856C29.8027 12.6856 27.9084 14.58 27.9084 16.9141V21.9884C27.9084 24.3225 29.8027 26.2169 32.1369 26.2169H36.3654Z" fill="#393939"/>
                    <path d="M13.5313 37.211C15.3996 37.211 16.9141 35.6964 16.9141 33.8281C16.9141 31.9599 15.3996 30.4453 13.5313 30.4453C11.663 30.4453 10.1484 31.9599 10.1484 33.8281C10.1484 35.6964 11.663 37.211 13.5313 37.211Z" fill="#393939"/>
                    <path d="M27.0628 37.211C28.9311 37.211 30.4456 35.6964 30.4456 33.8281C30.4456 31.9599 28.9311 30.4453 27.0628 30.4453C25.1945 30.4453 23.6799 31.9599 23.6799 33.8281C23.6799 35.6964 25.1945 37.211 27.0628 37.211Z" fill="#393939"/>
                    <path d="M37.2112 21.1934V23.6797H32.137C31.2067 23.6797 30.4456 22.9186 30.4456 21.9883V16.9141C30.4456 15.9838 31.2067 15.2227 32.137 15.2227H34.3189L36.7715 19.5189C37.059 20.0263 37.2112 20.6014 37.2112 21.1934Z" fill="#393939"/>
                    <path d="M22.1237 3.38281H9.62414C6.17365 3.38281 3.38281 6.17365 3.38281 9.62414V20.4154C3.38281 21.3457 4.14395 22.1068 5.07423 22.1068H20.5507C22.2759 22.1068 23.6798 20.7029 23.6798 18.9777V4.93892C23.6798 4.07629 22.9863 3.38281 22.1237 3.38281ZM17.0325 11.9583L13.4975 15.375C13.2438 15.6118 12.9224 15.7302 12.6179 15.7302C12.2966 15.7302 11.9752 15.6118 11.7384 15.375L10.0301 13.7512C9.52266 13.2607 9.50574 12.4488 9.99625 11.9414C10.4698 11.434 11.2817 11.434 11.7892 11.9076L12.6179 12.7025L15.2735 10.1316C15.7809 9.64105 16.5759 9.65797 17.0664 10.1654C17.5569 10.6728 17.54 11.4678 17.0325 11.9583Z" fill="#393939"/>
                </svg>
                <p className="max-md:text-xs">
                    Delivery 
                </p>
            </div>
            <div className={styles.item+ " flex gap-2 max-md:gap-1 items-center"}>
                <svg className="max-md:w-3" xmlns="http://www.w3.org/2000/svg" width="42" height="41" viewBox="0 0 42 41" fill="none">
                    <path d="M29.672 4.11035H12.7579C7.68361 4.11035 4.30078 7.49318 4.30078 12.5674V22.7159C4.30078 27.7902 7.68361 31.173 12.7579 31.173V34.7757C12.7579 36.1289 14.2632 36.9407 15.3796 36.1796L22.9064 31.173H29.672C34.7463 31.173 38.1291 27.7902 38.1291 22.7159V12.5674C38.1291 7.49318 34.7463 4.11035 29.672 4.11035ZM21.2149 24.6949C20.5046 24.6949 19.9464 24.1198 19.9464 23.4263C19.9464 22.7328 20.5046 22.1578 21.2149 22.1578C21.9253 22.1578 22.4835 22.7328 22.4835 23.4263C22.4835 24.1198 21.9253 24.6949 21.2149 24.6949ZM23.3461 17.6755C22.6865 18.1153 22.4835 18.4028 22.4835 18.8764V19.2316C22.4835 19.9251 21.9084 20.5002 21.2149 20.5002C20.5215 20.5002 19.9464 19.9251 19.9464 19.2316V18.8764C19.9464 16.9144 21.3841 15.9503 21.9253 15.5782C22.5512 15.1553 22.7541 14.8678 22.7541 14.428C22.7541 13.5823 22.0607 12.8888 21.2149 12.8888C20.3692 12.8888 19.6758 13.5823 19.6758 14.428C19.6758 15.1215 19.1007 15.6966 18.4072 15.6966C17.7137 15.6966 17.1386 15.1215 17.1386 14.428C17.1386 12.1784 18.9654 10.3517 21.2149 10.3517C23.4645 10.3517 25.2913 12.1784 25.2913 14.428C25.2913 16.3562 23.8705 17.3203 23.3461 17.6755Z" fill="#3F3F3F"/>
                </svg>
                <p className="max-md:text-xs">
                    FAQ 
                </p>
            </div>
            <div className={styles.item+ " flex gap-2 max-md:gap-1 items-center"}>
                <svg className="max-md:w-3" xmlns="http://www.w3.org/2000/svg" width="35" height="31" viewBox="0 0 35 31" fill="none">
                    <path d="M34.2722 9.97601C34.2722 11.9888 33.9508 13.8494 33.3926 15.5746C33.2912 15.9298 32.8683 16.0313 32.5639 15.8114C30.7202 14.4414 28.4706 13.714 26.1534 13.714C20.2842 13.714 15.4975 18.5008 15.4975 24.37C15.4975 26.1967 15.9711 27.9896 16.8675 29.5795C17.1381 30.0531 16.8168 30.662 16.3093 30.476C12.233 29.089 3.99582 24.0317 1.32338 15.5746C0.765217 13.8494 0.443848 11.9888 0.443848 9.97601C0.443848 4.74954 4.65548 0.520996 9.84812 0.520996C12.9096 0.520996 15.6497 2.00944 17.358 4.29285C19.0663 2.00944 21.8064 0.520996 24.8679 0.520996C30.0606 0.520996 34.2722 4.74954 34.2722 9.97601Z" fill="#393939"/>
                </svg>

                <p className="max-md:text-xs">
                    Help & support  
                </p>
            </div>
            <div className={styles.item+ " flex gap-2 max-md:gap-1 items-center"}>
                <svg className="max-md:w-3" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                    <path d="M22.4459 21.2783V24.6273C22.4459 25.7944 21.4987 26.7415 20.3316 26.7415C19.1646 26.7415 18.2174 25.7944 18.2174 24.6273V21.2783C17.6085 20.9062 17.1349 20.3649 16.7966 19.7222H5.1089C4.17862 19.7222 3.41748 20.4833 3.41748 21.4136V27.3843C3.41748 32.8137 7.81516 37.2114 13.2446 37.2114H27.4018C32.8481 37.2114 37.2458 32.8137 37.2458 27.3843V21.4136C37.2458 20.4833 36.4847 19.7222 35.5544 19.7222H23.8667C23.5284 20.3649 23.0548 20.9062 22.4459 21.2783Z" fill="#393939"/>
                    <path d="M27.4187 3.38281H13.2446C7.81516 3.38281 3.41748 7.7805 3.41748 13.2099V15.4934C3.41748 16.4236 4.17862 17.1848 5.1089 17.1848H16.3906C16.7797 14.9521 18.9954 13.3622 21.4142 14.0049C22.7673 14.3601 23.8667 15.4595 24.2219 16.8127C24.2557 16.9311 24.2557 17.0664 24.2726 17.1848H35.5544C36.4847 17.1848 37.2458 16.4236 37.2458 15.4934V13.2099C37.2458 7.7805 32.8481 3.38281 27.4187 3.38281Z" fill="#393939"/>
                </svg>
                <p className="max-md:text-xs">
                    Payments  
                </p>
            </div>
            <div className={styles.item+ " flex gap-2 max-md:gap-1 items-center"}>
                <svg className="max-md:w-3" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                    <path d="M25.6051 38.4796C25.1484 38.4796 24.7255 38.2259 24.5056 37.8368C24.2857 37.4478 24.2857 36.9573 24.5225 36.5683L26.2985 33.6083C26.6537 32.9994 27.4318 32.8133 28.0407 33.1685C28.6496 33.5237 28.8357 34.3018 28.4805 34.9107L28.0238 35.6718C32.6921 34.5724 36.1933 30.3777 36.1933 25.3711C36.1933 24.6776 36.7684 24.1025 37.4619 24.1025C38.1554 24.1025 38.7304 24.6776 38.7304 25.3711C38.7135 32.5935 32.8274 38.4796 25.6051 38.4796Z" fill="#393939"/>
                    <path d="M3.61671 16.4913C2.92323 16.4913 2.34814 15.9162 2.34814 15.2227C2.34814 8.00039 8.23427 2.11426 15.4566 2.11426C15.9133 2.11426 16.3362 2.36797 16.556 2.757C16.7759 3.14602 16.7759 3.63653 16.5391 4.02556L14.7631 7.00245C14.4079 7.59445 13.6299 7.79742 13.021 7.42531C12.429 7.07011 12.226 6.29206 12.5981 5.68315L13.0548 4.92201C8.36959 6.02143 4.88527 10.2161 4.88527 15.2227C4.88527 15.9162 4.31019 16.4913 3.61671 16.4913Z" fill="#393939"/>
                    <path d="M18.2811 23.4262L12.97 20.5677C12.4118 20.2632 11.7353 20.2632 11.1771 20.5677L5.86606 23.4262C5.47703 23.6292 5.24023 24.052 5.24023 24.5087C5.24023 24.9654 5.47703 25.3882 5.86606 25.5912L11.1771 28.4497C11.4646 28.6019 11.7691 28.6696 12.0736 28.6696C12.378 28.6696 12.6825 28.6019 12.97 28.4497L18.2811 25.5912C18.6701 25.3882 18.9069 24.9654 18.9069 24.5087C18.9069 24.052 18.6532 23.6461 18.2811 23.4262Z" fill="#393939"/>
                    <path d="M10.2978 29.448L5.35886 26.9785C4.98675 26.7924 4.54698 26.8094 4.17487 27.0292C3.83658 27.2491 3.6167 27.6382 3.6167 28.061V32.7293C3.6167 33.5412 4.05647 34.2685 4.78378 34.6237L9.72271 37.0932C9.89186 37.1608 10.0779 37.2116 10.264 37.2116C10.4839 37.2116 10.7037 37.1439 10.9067 37.0255C11.2619 36.8056 11.4818 36.4166 11.4818 35.9938V31.3254C11.4818 30.5305 11.0251 29.8032 10.2978 29.448Z" fill="#393939"/>
                    <path d="M19.956 27.0292C19.6008 26.8094 19.1611 26.7924 18.772 26.9785L13.8331 29.448C13.1058 29.8032 12.666 30.5305 12.666 31.3424V36.0107C12.666 36.4335 12.8859 36.8225 13.2411 37.0424C13.4441 37.1439 13.664 37.2116 13.8838 37.2116C14.0699 37.2116 14.2559 37.1608 14.4251 37.0763L19.364 34.6068C20.0913 34.2516 20.5311 33.5243 20.5311 32.7124V28.0441C20.5311 27.6382 20.3112 27.2491 19.956 27.0292Z" fill="#393939"/>
                    <path d="M35.1956 6.47795L29.8846 3.61945C29.3264 3.315 28.6498 3.315 28.0917 3.61945L22.7806 6.47795C22.3916 6.68092 22.1548 7.10377 22.1548 7.56045C22.1548 8.01714 22.3916 8.43999 22.7806 8.64296L28.0917 11.5015C28.3792 11.6537 28.6837 11.7213 28.9881 11.7213C29.2926 11.7213 29.597 11.6537 29.8846 11.5015L35.1956 8.64296C35.5846 8.43999 35.8214 8.01714 35.8214 7.56045C35.8214 7.08686 35.5677 6.68092 35.1956 6.47795Z" fill="#393939"/>
                    <path d="M27.2119 12.4826L22.2729 10.0132C21.9008 9.82711 21.461 9.84403 21.0889 10.0639C20.7506 10.2838 20.5308 10.6728 20.5308 11.0957V15.764C20.5308 16.5759 20.9705 17.3032 21.6978 17.6584L26.6368 20.1278C26.8059 20.2124 26.992 20.2632 27.178 20.2632C27.3979 20.2632 27.6178 20.1955 27.8208 20.0771C28.176 19.8572 28.3958 19.4682 28.3958 19.0453V14.377C28.3958 13.5651 27.9392 12.8378 27.2119 12.4826Z" fill="#393939"/>
                    <path d="M36.8701 10.0639C36.5149 9.84403 36.0751 9.82711 35.6861 10.0132L30.7472 12.4826C30.0198 12.8378 29.5801 13.5651 29.5801 14.377V19.0453C29.5801 19.4682 29.8 19.8572 30.1552 20.0771C30.3581 20.1955 30.578 20.2632 30.7979 20.2632C30.984 20.2632 31.17 20.2124 31.3391 20.1278L36.2781 17.6584C37.0054 17.2863 37.4452 16.559 37.4452 15.764V11.0957C37.4452 10.6728 37.2253 10.2838 36.8701 10.0639Z" fill="#393939"/>
                </svg>
                <p className="max-md:text-xs">
                    Size Change  
                </p>
            </div>
        </div>
    )
}

export default Faq;