import logo from './logo.svg';
import './App.css';
import HomePage from './components/index';
import { Route, Routes, useLocation } from 'react-router-dom';
import Signin from './components/auth/signin';
import Login from './components/auth/login';
import ShopPage from './components/shop';
import Header from './components/header';
import Footer from './components/footer';
import SingleShop from './components/shop/single';
import CartPage from './components/cart';
import PersonalInformation from './components/form/personalInformation';
import Addresses from './components/form/address';
import Panel from './components/panel';
import OrderHistory from './components/panel/orderHistory';
import Search from './components/search';
import CategoryPage from './components/category';
import TopHeader from './components/header/top';
import About from './components/privacy/About';
import Privacy from './components/privacy/Privacy';
import Shipping from './components/privacy/Shipping';
import { useEffect, useState } from 'react';
import { getSessionId } from './api/cart';
import Conditions from './components/privacy/Conditions';
import AddressList from './components/form/addressList';
import Contact from './components/privacy/Contact';
import PanelAdress from './components/panel/panelAdress';
import PanelAddAddress from './components/panel/panelAddAddress';
import PrivateRoute from './routes/PrivateRoute';
import SuccessPay from './components/payment/successPay';
import CancelPay from './components/payment/cancelPay';
import UnSuccessPay from './components/payment/unSuccessPay';
import FirstForgotPassword from './components/auth/forgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import CookiesConsent from './components/privacy/CookiesConsent ';
import SingleBlog from './components/blog/singleBlog';

function App() {


  const location = useLocation();
  const hideHeaderFooterRoutes = ['/login' , '/signin' , '/reset-password' , '/forgot-pass'];

  const shouldShowHeaderFooter = !hideHeaderFooterRoutes.some(path => location.pathname.startsWith(path));



  return (
    <>

      {shouldShowHeaderFooter && (
        <>
          <TopHeader />
          <Header />
        </>
      )}
    {/* <TopHeader />
      <Header /> */}
        <Routes>
          <Route path='/' element={ <HomePage /> } />
          <Route path='/signin' element={ <Signin /> } />
          <Route path='/login' element={ <Login /> } />
          <Route path='/list/:slug' element={ <ShopPage /> } />
          <Route path='/shop/:slug' element={ <SingleShop /> } />
          <Route path='/cart' element={ <CartPage /> } />
          <Route path='/forgot-pass' element={ <FirstForgotPassword /> } />
          <Route path='/reset-password' element={ <ResetPassword /> } />
          <Route path='/blog/:slug' element={ <SingleBlog /> } />
          <Route path='/add-address' element={
            <PrivateRoute>
              <Addresses /> 
            </PrivateRoute>
          } />
          <Route path='/panel' element={ 
            <PrivateRoute>
              <Panel /> 
            </PrivateRoute>
          } />
          <Route path='/panel/address-list' element={ 
            <PrivateRoute>
              <PanelAdress /> 
            </PrivateRoute>
          } />
          <Route path='/panel/order-history' element={ 
            <PrivateRoute>
              <OrderHistory /> 
            </PrivateRoute>
          } />
          <Route path='/panel/personal-information' element={ 
            <PrivateRoute>
              <PersonalInformation /> 
            </PrivateRoute>
          } />
          <Route path='/panel/add-address' element={ 
            <PrivateRoute>
              <PanelAddAddress /> 
            </PrivateRoute>
          } />
          <Route path='/search' element={ <Search /> } />
          <Route path='/category' element={ <CategoryPage /> } />
          <Route path='/about' element={ <About /> } />
          <Route path='/privacy' element={ <Privacy /> } />
          <Route path='/shipping-delivery' element={ <Shipping /> } />
          <Route path='/conditions' element={ <Conditions /> } />
          <Route path='/address-list' element={ 
            <PrivateRoute>
              <AddressList /> 
            </PrivateRoute>
          } />
          <Route path='/success-pay' element={ 
            <PrivateRoute>
              <SuccessPay /> 
            </PrivateRoute>
          } />
          <Route path='/cancel-pay-pay' element={ 
            <PrivateRoute>
              <CancelPay /> 
            </PrivateRoute>
          } />
          <Route path='/unsuccess-pay' element={ 
            <PrivateRoute>
              <UnSuccessPay /> 
            </PrivateRoute>
          } />
          <Route path='/contact' element={ <Contact /> } />
        </Routes>
      {shouldShowHeaderFooter && <Footer />}
      <CookiesConsent />
    </>
  );
}

export default App;
