import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { getAllOrderHistory } from "../../api/user";
import { domain } from "../../api/domain";

const OrderHistory = () => {

    const [orderHistroy , setOrderHistory] = useState(null);


    // get all user information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAllOrderHistory();
                setOrderHistory(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    
    




    return(
        <div className={styles.orderHistory + " w-11/12 container mx-auto"}>
            <div className={styles.items + " flex flex-col gap-8"}>
                {orderHistroy?.map((item) => (
                    <div className="flex max-md:flex-col justify-between items-start gap-2">
                        <div className={styles.cartpopup__product + " w-2/5 max-md:w-full flex flex-col gap-2"}>
                            <div className={styles.product + " flex gap-4"}>
                                <div className={styles.image + " w-[150px] overflow-hidden"}>
                                    <img className="object-cover w-full" src={domain + item?.image.substring(1)} alt="image" />
                                </div>
                                <div className={styles.desc + " flex flex-col gap-2"}>
                                    <p className={styles.title + " text-xs text-[#2B2B2B]"}>{item?.product}</p>
                                    <p className={styles.item + " text-xs text-[#2B2B2B]"}>Size: <span className="font-bold text-black">{item?.size}</span></p>
                                    <p className={styles.item + " text-xs text-[#2B2B2B]"}>Color: <span className="font-bold text-black">{item?.color}</span></p>
                                    <p className={styles.item + " text-xs text-[#2B2B2B]"}>Quantity: <span className="font-bold text-black">{item?.quantity}</span></p>
                                    <p className={styles.item + " text-xs text-[#2B2B2B]"}>Price: <span className="font-bold text-black">{item?.price}</span><span className="font-bold text-black"> AED</span></p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.total + " w-1/5 max-md:w-full flex flex-col gap-4"}>
                            <div className={styles.price + " text-xs flex justify-between items-center"}>
                                <p className="font-bold">Total:</p>
                                <p className="font-bold">{item?.price * item?.quantity} <span>AED</span></p>
                            </div>
                        </div>
                        <div className={styles.date + " w-1/5 max-md:w-full"}>
                            <p className="text-xs">Date: <span>{item?.created}</span></p>
                            <p className="text-xs">Refrence Number: <span>{item?.trace}</span></p>
                            <div className="flex gap-2 items-center mt-2">
                                <p className="font-bold text-sm">Completed</p>
                                <div className="w-3 h-3 bg-[#1DC57D]"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OrderHistory;