import React from "react";
import axios from "axios";
import { domain } from "../domain";


export const getAllShopList = async (slug , pageNumber) => {
    try {
        const response = await axios.get(domain + "api/" + 'product/list?' + "slug=" + slug + "&page_number=" + pageNumber);
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};



export const getShowSingleShop = async (slug) => {
    try {
        const response = await axios.get(domain + "api/" + 'product?' + "slug=" + slug);
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};



export const getColorOfEachSize = async (slug , size) => {
    try {
        const response = await axios.get(domain + "api/" + 'product/color_size?' + "slug=" + slug + "&size=" + size);
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};



export const getPopularProducts = async () => {
    try {
        const response = await axios.get(domain + "api/" + 'product/popular_product/');
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};


export const getPriceOfEachSizeAndColor = async (product , color , size) => {
    try {
        const response = await axios.get(domain + "api/" + 'product/variant?product=' + product + "&size=" + size + "&color=" + color);
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};


export const getAllProductImages = async (product , color) => {
    try {
        const response = await axios.get(domain + "api/" + 'product/colorimage?color=' + color + "&product=" + product);
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};


export const getAllAvaibleSizes = async (product , color) => {
    try {
        const response = await axios.get(domain + "api/" + 'product/sizeofcolor?product=' + product + "&color=" + color);
        

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};