import React from "react";
import styles from "./styles.module.scss";

const Conditions = () => {
    return(
        <div className={styles.privacy}>
            <div className={styles.privacywrapper + "  w-11/12 container mx-auto"}>
                <div className={styles.privacy__header + " w-full p-2 mb-5 rounded-lg"}>
                    <p className="font-bold">
                        Terms and Conditions
                    </p>
                </div>
                <div className={styles.privacy__desc + " p-4 rounded-lg mb-10"}>
                    <div className="flex-col gap-10">
                        <p>
                            Please read these terms and conditions carefully before using Our Service.
                            <br/>
                            Interpretation and Definitions
                            <br/>
                            Interpretation
                            <br/>
                            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                            <br/>
                            Definitions
                            <br/>
                            For the purposes of these Terms and Conditions:
                            <br/>
                            • Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                            <br/>
                            • Country refers to: United Arab Emirates
                            <br/>
                            • Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Satelec LLC FZ, 610 Clover Bay Tower, Business Bay.
                            <br/>
                            • Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                            <br/>
                            • Service refers to the Website.
                            <br/>
                            • Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the Free Terms and Conditions Generator.
                            <br/>
                            • Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
                            <br/>
                            • Website refers to Healfit, accessible from https://www.healfit.ae
                            <br/>
                            • You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                            <br/>
                            Acknowledgment
                            <br/>
                            These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
                            <br/>
                            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
                            <br/>
                            By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.
                            <br/>
                            You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                            <br/>
                            Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
                            <br/>
                            SITE OPERATION 
                            <br/>
                            United Arab Emirates is our Country of Domicile. HEALFIT controls this Site from the U.A.E. HEALFIT makes no representation that this Site is appropriate for use in other locations. If you use this Site from other locations, you are responsible for ensuring compliance with local laws. You may not use, export or re-export any materials from this Site in violation of any applicable laws or regulations, including, but not limited to any U.A.E export laws and regulations.
                            <br/>
                            APPLICABLE LAW. The Laws of the United Arab Emirates shall govern the use of the Site and the Terms of Use, without regards to conflict of laws principals. All disputes arising in connection therewith shall be heard only by a court of competent jurisdiction in U.A.E.
                            <br/>
                            MULTI-CURRENCY PRICED TRANSACTION 
                            <br/>
                            The displayed price and currency selected by you, will be the same price and currency charged to the Card and printed on the Transaction Receipt.
                            <br/>
                            PURCHASES. HEALFIT accepts payment by Visa or Mastercard debit and credit cards in AED for its products and services. All online purchases are also governed by the terms and conditions of respective merchant service providers. Please review respective merchant service provider’s user agreement and privacy policy before entering any transaction. Interpretation. These Terms of Use supersede all other written and oral communications or agreements with regard to the subject matters discussed in these Terms of Use. A waiver or modification of these Terms of Use will only be effective if made in writing signed by an authorized officer of HEALFIT. All refunds will be made onto the original mode of payment.
                            <br/>
                            OFFICE FOREIGN ASSETS CONTROL (OFAC) SANCTIONED COUNTRIES 
                            <br/>
                            <br/>
                            <br/>
                            HEALFIT will not trade with or provide any services to individuals and companies owned or controlled by, or acting for or on behalf of, OFAC targeted countries AND individuals, groups, and entities, such as terrorists and narcotics traffickers designated under the OFAC programs that are not country specific.
                            <br/>
                            REPRESENTATIONS BY YOU 
                            <br/>
                            <br/>
                            <br/>
                            By visiting the Site, you represent, warrant and covenant that (a) you are at least 18 years old; (b) that all materials of any kind submitted by you to HEALFIT through the Site or for inclusion on the Site will not plagiarize, violate or infringe upon the rights of any third-party including trade secret, copyright, trademark, trade dress, privacy, patent, or other personal or proprietary rights. The customer using the website who are Minor /under the age of 18 shall not register as a User of the website and shall not transact on or use the website.
                            <br/>
                            PERMITTED USE 
                            <br/>
                            <br/>
                            <br/>
                            You agree that you are only authorized to visit, view and to retain a copy of pages of this Site for your own personal use, that you shall not duplicate, download, publish, modify or otherwise distribute the material on this Site for any purpose other than for personal use, unless otherwise specifically authorized by HEALFIT to do so. You also agree not to deep-link to the site for any purpose, unless specifically authorized by HEALFIT to do so. The content and software on this Site is the property of HEALFIT. The cardholder must retain a copy of transaction records and Merchant policies and rules.
                            <br/>
                            YOUR ACCOUNT 
                            <br/>
                            <br/>
                            <br/>
                            If you use HEALFIT Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your account from any devices, and you agree to accept responsibility for all activities that occur under your account or password. The Site shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this section.
                            <br/>
                            NO COMMERCIAL USE 
                            <br/>
                            <br/>
                            <br/>
                            This Site may not be used by you for any commercial purposes such as to conduct sales of merchandise or services of any kind. You must obtain HEALFIT’s prior written consent to make commercial offers of any kind on the Site, whether by advertising, solicitations, links, or any other form of communication. HEALFIT will investigate and take appropriate legal action against anyone who violates this provision, including without limitation, removing the offending communication from the Site and barring such violators from use of the Site.
                            <br/>
                            LINKS AND SEARCH RESULTS 
                            <br/>
                            <br/>
                            <br/>
                            The Site may automatically produce search results that reference and/or link to third party sites throughout the World Wide Web. HEALFIT has no control over these sites or the content within them. HEALFIT does not guarantee, represent or warrant that the content contained in the sites is accurate, legal and/or inoffensive. HEALFIT does not endorse the content of any third party site, nor does it make any representation or warranty about these sites, including that they will not contain viruses or otherwise impact your computer. By using the Site to search for or link to another site, you agree and understand that you may not make any claim against HEALFIT for any damages or losses, whatsoever, resulting from your use of the Site to obtain search results or to link to another site. If you have a problem with a link from the Site, you may notify us at support@HEALFIT.AE.
                            <br/>
                            <br/>
                            <br/>
                            COPYRIGHT POLICY
                            <br/>
                            <br/>
                            <br/>
                            HEALFIT may terminate the privileges of any user who uses this Site to unlawfully transmit copyrighted material without a license, express consent, valid defense or fair use exemption to do so. If you submit information to this Site, you warrant that the information does not infringe the copyrights or other rights of third parties.
                            <br/>
                            <br/>
                            <br/>
                            INTELLECTUAL PROPERTY
                            <br/>
                            <br/>
                            <br/>
                            Although HEALFIT is not responsible for the content, quality or accuracy of data provided by users, compilations of such data, text, information, and other materials made available to users through HEALFIT’s system. The Online Materials are HEALFIT’s intellectual property and are protected by U.S. and international intellectual property laws. The Online Materials may not be copied or redistributed either in whole or in part without prior written consent of HEALFIT, except as expressly and specifically permitted under these Terms of Use.
                            <br/>
                            The Online Materials are and will remain the exclusive property of HEALFIT. All rights, titles and interests in and to the Online Materials will be and remain vested solely in HEALFIT. Under no circumstances will you have any right, directly or indirectly, to own, use, copy, loan, sell, rent, lease, license, sublicense, redistribute, assign or otherwise convey the On- line Materials, or any rights thereto, except as expressly and specifically provided in the Terms of Use. Nothing in these Terms of Use will convey to you any right, title or interest, except that of a license with the express rights and subject to all limitations herein. Nothing in these Terms of Use grants you the right, directly or indirectly, to use the Online Materials to create a product for resale or to use the Online Materials in any way that competes with HEALFIT.
                            <br/>
                            You acknowledge and agree that HEALFIT will own all rights, titles and interests in and to any copy, translation, modification, adaptation, derivative work or improvement of the On- line Materials made by or for you. At HEALFIT’s request, you must execute, or obtain the execution of, any instrument that may be necessary to assign these rights, titles or interests to HEALFIT or perfect these rights, titles or interests in HEALFIT’s name. 
                            <br/>
                            DISCLAIMER OF WARRANTY, LIMITATION OF DAMAGES. HEALFIT MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTY OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,NON-INFRINGEMENT OR OTHER VIOLATION OF RIGHTS IN RELATION TO THE AVAILABILITY, ACCURACY, VALIDITY, RELIABILITY OR CONTENT OF THESE PAGES AND/OR THE SITE. HEALFIT ALSO DOES NOT MAKE ANY REPRESENTATION OR WARRANTY REGARDING THE ACCURACY OR RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION THAT IS SUBMITTED, DISPLAYED OR UPLOADED THROUGH THE SITE BY ANY USER. HEALFIT SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR FOR BUSINESS INTERRUPTION ARISING OUT OF THE USE OF OR INABILITY TO USE THIS SITE, EVEN IF HEALFIT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS OF LIABILITY, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THE LIABILITY OF HEALFIT WOULD IN SUCH CASE BE LIMITED TO THE GREATEST EXTENT OF LIABILITY PERMITTED BY LAW.
                            <br/>
                            VIOLATION OF TERMS OF USE
                            <br/>
                            <br/>
                            <br/>
                            You understand and agree that in HEALFIT’s sole discretion, and without prior notice, HEALFIT may terminate your access to the Site, or exercise any other remedy available and remove any unauthorized user information, if HEALFIT believes that the information you provide has violated or is inconsistent with these Terms of Use, or violates the rights of HEALFIT, or any third party, or violates the law. You agree that monetary damages may not provide a sufficient remedy to HEALFIT for violations of these Terms of Use and you consent to injunctive or other equitable relief for such violations. HEALFIT may release user information about you if required by law or subpoena.
                            <br/>
                            INDEMNITY 
                            <br/>
                            <br/>
                            <br/>
                            You agree to indemnify and hold HEALFIT, its subsidiaries, affiliates, officers, agents and other partners and employees, harmless from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of or relating to your use of the Site, including also your use of the Site to provide a link to another site or to upload content or other information to the Site, or your breach of the Terms of Use.
                            <br/>
                            LICENCE GRANTED TO YOU 
                            <br/>
                            <br/>
                            <br/>
                            By providing materials to HEALFIT, including by submitting or uploading content or materials for use on the Site you represent and warrant that you or the owner of all rights to such content or materials has expressly granted HEALFIT an irrevocable world-wide right in all languages and in perpetuity to use and exploit all or any part of the content and materials provided by you. HEALFIT may publish and distribute any such submitted content or materials at its sole discretion by any method now existing or later developed. You agree that you shall waive all claims and have no recourse against HEALFIT for any alleged or actual infringement or misappropriation of any proprietary rights in any communication, content or material submitted to HEALFIT. Any communication or materials you send to HEALFIT will be treated as non- confidential and non-proprietary and may be disseminated or used by HEALFIT for any purpose, including, but not limited to, developing, creating, manufacturing or marketing products or services.
                            <br/>
                            <br/>
                            <br/>
                            ADVERTISING 
                            <br/>
                            The Site may contain advertisements and/or sponsorships. The advertisers and/or sponsors that provide these advertisements and sponsorships are solely responsible for insuring that the materials submitted for inclusion on the Site are accurate and comply with all applicable laws. HEALFIT is not responsible for the acts or omissions of any advertiser or sponsor.
                            <br/>
                            <br/>
                            <br/>
                            SEVERABILITY
                            <br/>
                            If any provision of the Terms of Use is held to be invalid, void or unenforceable, the remaining provisions shall nevertheless continue in full force and effect.
                            <br/>
                            Headings & section titles in this Agreement are for convenience and do not define, limit, or extend any provision of this Agreement.
                            <br/>
                            Changes to These Terms and Conditions
                            <br/>
                            We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
                            <br/>
                            By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
                            <br/>
                            Contact Us
                            <br/>
                            If you have any questions about these Terms and Conditions, You can contact us:
                            <br/>
                            • By email: info@healfit.ae
                            <br/>
                            • By visiting this page on our website: https://www.healfit.ae
                            <br/>
                            • By phone number: +97144495097
                            <br/>
                            • By mail: 610 Clover Bay Tower, Business Bay
                            <br/>
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Conditions;