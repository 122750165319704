import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { BluePrimaryButton } from "../button";
import Banner from "../banner";
import Faq from "../faq";
import Subscribe from "../index/subscribe";
import axios from "axios";
import { domain } from "../../api/domain";
import { getCookie } from "../../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";  

const PanelAddAddress = () => {


    const navigate = useNavigate();
    const token = getCookie('token')
    const [formData, setFormData] = useState({
        address: "",
        additional_information: "",
        emirats: "",
        city: "",
        country: "",
        phone_number: "",
    });
    const [loading , setLoading] = useState(false);
    const [error , setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true)

        axios.post(domain + 'api/accounts/address/', formData , {
            headers : {
                'Authorization' : 'Bearer ' + token,
            }
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                setError(error.response.data);
            })
            .finally(() => {
                console.log("final");
                setLoading(false);
            });
    };
    

    return(
        <div className={styles.formpage}>
            <div className={styles.form + " container mx-auto mb-20"}>
                <div className={styles.formwrapper + " flex flex-col gap-5"}>
                    <div className={styles.form__form}>
                        <form className="flex items-start gap-5 flex-col">
                                <div className={styles.formgroup + " w-full flex flex-col items-center gap-1 mb-3"}>
                                    {error?.address ? 
                                        <input 
                                            className="redinput placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" 
                                            name="address" 
                                            value={formData?.address} 
                                            placeholder="ADDRESS*  " 
                                            onChange={handleChange} 
                                        /> : 
                                        <input 
                                            className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" 
                                            name="address" 
                                            value={formData?.address} 
                                            placeholder="ADDRESS*  " 
                                            onChange={handleChange} 
                                        />
                                    }
                                    {error?.address && <p className="text-xs mr-auto text-[#ff0000] mt-1">{error?.address}</p>}
                                </div>
                                <div className={styles.formgroup + " w-full flex flex-col items-center gap-1 mb-3"}>
                                    {error?.additional_information ? 
                                    <input 
                                        className="redinput placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                        type="text" 
                                        name="additional_information" 
                                        value={formData?.additional_information} 
                                        placeholder="ADDITIONAL iNFORMATION  " 
                                        onChange={handleChange} 
                                    /> : 
                                    <input 
                                        className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                        type="text" 
                                        name="additional_information" 
                                        value={formData?.additional_information} 
                                        placeholder="ADDITIONAL iNFORMATION  " 
                                        onChange={handleChange} 
                                    />
                                    }
                                    {error?.additional_information && <p className="text-xs mr-auto text-[#ff0000] mt-1">{error?.additional_information}</p>}
                                </div>
                                <div className={styles.formgroup + " w-full flex flex-col items-center gap-1 mb-3"}>
                                    {error?.emirats ? 
                                        <input 
                                            className="redinput placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" 
                                            name="emirats" 
                                            value={formData?.emirats} 
                                            placeholder="EMIRATE*  " 
                                            onChange={handleChange} 
                                        /> : 
                                        <input 
                                            className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" 
                                            name="emirats" 
                                            value={formData?.emirats} 
                                            placeholder="EMIRATE*  " 
                                            onChange={handleChange} 
                                        />
                                    }
                                    {error?.emirats && <p className="text-xs mr-auto text-[#ff0000] mt-1">{error?.emirats}</p>}
                                </div>
                                <div className={styles.formgroup + " w-full flex flex-col items-center gap-1 mb-3"}>
                                    {error?.city ? 
                                        <input 
                                            className="redinput placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" 
                                            name="city" 
                                            value={formData?.city} 
                                            placeholder="CITY*  " 
                                            onChange={handleChange} 
                                        /> : 
                                        <input 
                                            className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" 
                                            name="city" 
                                            value={formData?.city} 
                                            placeholder="CITY*  " 
                                            onChange={handleChange} 
                                        />
                                    }
                                    {error?.city && <p className="text-xs mr-auto text-[#ff0000] mt-1">{error?.city}</p>}
                                </div>
                                <div className={styles.formgroup + " w-full flex flex-col items-center gap-1 mb-3"}>
                                    {error?.country ?
                                        <input 
                                            className="redinput placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" name="country" 
                                            value={formData?.country} 
                                            placeholder="COUNTRY*  " 
                                            onChange={handleChange} 
                                        /> : 
                                        <input 
                                            className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" name="country" 
                                            value={formData?.country} 
                                            placeholder="COUNTRY*  " 
                                            onChange={handleChange} 
                                        />
                                    }
                                    {error?.country && <p className="text-xs mr-auto text-[#ff0000] mt-1">{error?.country}</p>}
                                </div>
                                <div className={styles.formgroup + " w-full flex flex-col items-center gap-1 mb-3"}>
                                    {error?.phone_number ? 
                                        <input 
                                            className="redinput placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" name="phone_number" 
                                            value={formData?.phone_number} 
                                            placeholder="PHONE*  " 
                                            onChange={handleChange} 
                                        /> : 
                                        <input 
                                            className="placeholder-opacity-25 w-full py-4 px-5 text-xs tracking-widest" 
                                            type="text" name="phone_number" 
                                            value={formData?.phone_number} 
                                            placeholder="PHONE*  " 
                                            onChange={handleChange} 
                                        />
                                    }
                                    {error?.phone_number && <p className="text-xs mr-auto text-[#ff0000] mt-1">{error?.phone_number}</p>}
                                </div>
                        </form>
                    </div>
                    <div className={styles.form__form + " mt-10"}>
                        <form className="flex items-start gap-5 flex-col">
                            <div className={styles.button + " flex ml-auto"} onClick={handleSubmit}>
                                {loading == true? 
                                    <BluePrimaryButton>                                
                                        <svg class="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="transparent" stroke-dasharray="31.42 31.42" stroke-dashoffset="10"></circle>
                                        </svg>
                                        Processing...
                                    </BluePrimaryButton>
                                    :
                                    <BluePrimaryButton>
                                        Add Address
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                            <path d="M20.7974 2.77344H10.3698C5.84036 2.77344 3.14014 5.47366 3.14014 10.0031V20.4182C3.14014 24.9601 5.84036 27.6603 10.3698 27.6603H20.7849C25.3143 27.6603 28.0146 24.9601 28.0146 20.4307V10.0031C28.027 5.47366 25.3268 2.77344 20.7974 2.77344ZM23.7091 15.8764L18.3709 21.2146C18.1843 21.4013 17.9478 21.4884 17.7114 21.4884C17.475 21.4884 17.2386 21.4013 17.0519 21.2146C16.691 20.8538 16.691 20.2565 17.0519 19.8956L20.7974 16.1501H8.11751C7.60733 16.1501 7.18425 15.7271 7.18425 15.2169C7.18425 14.7067 7.60733 14.2836 8.11751 14.2836H20.7974L17.0519 10.5381C16.691 10.1773 16.691 9.58 17.0519 9.21914C17.4128 8.85828 18.01 8.85828 18.3709 9.21914L23.7091 14.5574C23.8834 14.7316 23.9829 14.968 23.9829 15.2169C23.9829 15.4657 23.8834 15.7022 23.7091 15.8764Z" fill="white"/>
                                        </svg>
                                    </BluePrimaryButton>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <Banner />

            <div className="mt-10">
                <Faq />
            </div>

            <Subscribe />
        </div>
    )
}

export default PanelAddAddress;