import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { SecondBlackPrimaryButton } from "../button";
import { Link } from "react-router-dom";
import { domain } from "../../api/domain";

const Category = ({category , category_title , description , id , image , slug}) => {


    return(
        <div className={styles.category}>
            <Link to={"/list/" + slug}>
                <img src={domain + image?.substring(1)} alt="image" />
                <div className={styles.category__desc + " flex flex-col justify-between max-md:py-2 py-10 max-md:px-2 px-6"}>
                    <div className={styles.top + " flex flex-col gap-1"}>
                        <p className={styles.title + " max-md:text-[10px] max-md:leading-3 text-3xl text-white text-left font-semibold"}>
                            {category_title}
                        </p>
                        <p className={styles.desc + " max-md:text-[6px] max-md:leading-3 text-sm text-white text-left font-normal"}>
                            {description}
                        </p>
                    </div>
                    <div className={styles.bottom + " flex justify-end max-md:justify-center"}>
                        <SecondBlackPrimaryButton>
                            Discover 
                        </SecondBlackPrimaryButton>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Category;