import React, { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { getPopularProducts } from '../../api/shop';
import { domain } from '../../api/domain';
import { Link } from 'react-router-dom';

const EmblaCarousel = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);

  const [popularProduct, setPopularProduct] = useState(null);
  const [images, setImages] = useState([]);

  // Fetch popular products from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPopularProducts();
        setPopularProduct(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Update images array when popularProduct data changes
  useEffect(() => {
    if (popularProduct) {
      const newImages = popularProduct.map(item => ({
        url: domain + item?.popular?.image1?.substring(1),
        desc: item?.popular?.product,
        price: item?.popular?.price,
        slug: item?.popular?.slug,
      }));
      setImages(prevImages => [...prevImages, ...newImages]);
    }
  }, [popularProduct]);

  // Render the EmblaCarousel component only when images are available
  return (
    <div className="embla">
      {images.length > 0 && (
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((index) => {
              const { price, url, desc , slug } = images[index % images.length];
              return (
                <div className="embla__slide" key={index}>
                  <div className="embla__slide__number">
                    <span>{index + 1}</span>
                  </div>
                  <Link to={"/shop/" + slug}>
                    <img
                      className="embla__slide__img"
                      src={url}
                      alt={`Slide ${index + 1}`}
                    />
                  </Link>
                  <div className="embla__slide__desc">
                    <p>{desc}</p>
                  </div>
                  <div className='embla__slide__price'>
                    <p>{price} AED</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmblaCarousel;
