import React from "react";
import styles from "./styles.module.scss";

const TopHeader = () => {
    return(
        <div className={styles.topheader + " max-md:hidden py-2 flex justify-between items-center bg-[#3F3F3F] px-20"}>
            <div className={styles.numbers + " flex items-center gap-20"}>
                <div className={styles.item + " flex items-center gap-5"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <path d="M9.54417 5.82279C9.31125 5.82279 9.12709 5.6332 9.12709 5.4057C9.12709 5.20529 8.92667 4.7882 8.59084 4.42529C8.26042 4.0732 7.8975 3.86737 7.59417 3.86737C7.36125 3.86737 7.17709 3.67779 7.17709 3.45029C7.17709 3.22279 7.36667 3.0332 7.59417 3.0332C8.13584 3.0332 8.70459 3.3257 9.20292 3.85112C9.66875 4.34404 9.96667 4.95612 9.96667 5.40029C9.96667 5.6332 9.77709 5.82279 9.54417 5.82279Z" fill="#27BDBE"/>
                        <path d="M11.4996 5.82308C11.2667 5.82308 11.0825 5.6335 11.0825 5.406C11.0825 3.48308 9.51709 1.92308 7.59959 1.92308C7.36667 1.92308 7.1825 1.7335 7.1825 1.506C7.1825 1.2785 7.36667 1.0835 7.59417 1.0835C9.9775 1.0835 11.9167 3.02266 11.9167 5.406C11.9167 5.6335 11.7271 5.82308 11.4996 5.82308Z" fill="#27BDBE"/>
                        <path d="M5.98542 8.09808L4.98334 9.10016C4.77209 9.31141 4.43625 9.31141 4.21959 9.10558C4.16 9.046 4.10042 8.99183 4.04084 8.93225C3.48292 8.36891 2.97917 7.7785 2.52959 7.161C2.08542 6.5435 1.72792 5.926 1.46792 5.31391C1.21334 4.69641 1.08334 4.106 1.08334 3.54266C1.08334 3.17433 1.14834 2.82225 1.27834 2.49725C1.40834 2.16683 1.61417 1.8635 1.90125 1.59266C2.24792 1.25141 2.62709 1.0835 3.02792 1.0835C3.17959 1.0835 3.33125 1.116 3.46667 1.181C3.6075 1.246 3.73209 1.3435 3.82959 1.48433L5.08625 3.25558C5.18375 3.391 5.25417 3.51558 5.30292 3.63475C5.35167 3.7485 5.37875 3.86225 5.37875 3.96516C5.37875 4.09516 5.34084 4.22516 5.265 4.34975C5.19459 4.47433 5.09167 4.60433 4.96167 4.73433L4.55 5.16225C4.49042 5.22183 4.46334 5.29225 4.46334 5.37891C4.46334 5.42225 4.46875 5.46016 4.47959 5.5035C4.49584 5.54683 4.51209 5.57933 4.52292 5.61183C4.62042 5.79058 4.78834 6.0235 5.02667 6.30516C5.27042 6.58683 5.53042 6.87391 5.81209 7.161C5.86625 7.21516 5.92584 7.26933 5.98 7.3235C6.19667 7.53475 6.20209 7.88141 5.98542 8.09808Z" fill="#27BDBE"/>
                        <path d="M11.9004 9.92873C11.9004 10.0804 11.8733 10.2375 11.8192 10.3891C11.8029 10.4325 11.7867 10.4758 11.765 10.5191C11.6729 10.7141 11.5537 10.8983 11.3967 11.0716C11.1312 11.3641 10.8387 11.5754 10.5083 11.7108C10.5029 11.7108 10.4975 11.7162 10.4921 11.7162C10.1725 11.8462 9.82583 11.9166 9.45208 11.9166C8.89958 11.9166 8.30916 11.7866 7.68625 11.5212C7.06333 11.2558 6.44041 10.8983 5.82291 10.4487C5.61166 10.2916 5.40041 10.1346 5.2 9.96665L6.97125 8.1954C7.12291 8.30915 7.25833 8.39581 7.37208 8.4554C7.39916 8.46623 7.43166 8.48248 7.46958 8.49873C7.51291 8.51498 7.55625 8.5204 7.605 8.5204C7.69708 8.5204 7.7675 8.4879 7.82708 8.42831L8.23875 8.02206C8.37416 7.88665 8.50416 7.78373 8.62875 7.71873C8.75333 7.6429 8.87791 7.60498 9.01333 7.60498C9.11625 7.60498 9.22458 7.62665 9.34375 7.6754C9.46291 7.72415 9.5875 7.79456 9.72291 7.88665L11.5158 9.15956C11.6567 9.25706 11.7542 9.37081 11.8137 9.50623C11.8679 9.64165 11.9004 9.77706 11.9004 9.92873Z" fill="#27BDBE"/>
                    </svg>
                    <p className="text-white text-sm">
                        +971 44495097
                    </p>
                </div>
                <div className={styles.item + " flex items-center gap-5"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_138_3222)">
                        <path d="M13.92 9.01988C13.5133 11.5132 11.6533 13.4199 9.18 13.8865C7.82 14.1465 6.53334 13.9399 5.42001 13.4199C5.23334 13.3266 4.94 13.2932 4.74 13.3332C4.32666 13.4332 3.62001 13.6065 3.02668 13.7399C2.45334 13.8799 2.1 13.5266 2.24667 12.9599L2.65335 11.2532C2.70001 11.0532 2.65334 10.7532 2.56668 10.5666C2.06001 9.50656 1.86001 8.27322 2.06668 6.97989C2.46668 4.49989 4.48001 2.48654 6.96668 2.07321C11.0733 1.41321 14.5733 4.90654 13.9 9.01321L13.92 9.01988Z" stroke="#27BDBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.5 5.66016C6.32667 5.66016 6.16 5.7335 6.01333 5.88017C5.88666 6.00017 5.8 6.12683 5.74667 6.2735C5.69333 6.4135 5.66 6.56683 5.66 6.72683C5.66 6.97349 5.71333 7.22682 5.82666 7.49349C5.94 7.76016 6.09333 8.02683 6.28666 8.2935C6.48 8.56016 6.70001 8.81349 6.94001 9.06016C7.18667 9.30016 7.44 9.52017 7.70667 9.7135C7.97333 9.90683 8.24666 10.0602 8.51333 10.1802C8.78 10.3002 9.04001 10.3535 9.28001 10.3535C9.44668 10.3535 9.59333 10.3202 9.73999 10.2668C9.87999 10.2068 10.0067 10.1202 10.1267 9.99349C10.1933 9.92016 10.2467 9.84017 10.2867 9.7535C10.3267 9.66683 10.3467 9.58016 10.3467 9.50016C10.3467 9.4335 10.3333 9.3735 10.3067 9.32017C10.28 9.26017 10.24 9.2135 10.18 9.1735L9.40666 8.62016C9.34666 8.58016 9.29333 8.54683 9.23999 8.52683C9.18666 8.50683 9.14 8.49349 9.1 8.49349C9.04 8.49349 8.98667 8.50683 8.93333 8.54016C8.88 8.56683 8.82 8.6135 8.76667 8.6735L8.58667 8.84682C8.58667 8.84682 8.53333 8.88682 8.49333 8.88682C8.47333 8.88682 8.45333 8.88683 8.43333 8.88017C8.42 8.8735 8.4 8.86683 8.39333 8.86017C8.31333 8.82017 8.21335 8.74683 8.09335 8.64683C7.96668 8.54016 7.84666 8.42684 7.71999 8.30017C7.59999 8.1735 7.48667 8.05351 7.38001 7.92684C7.28001 7.80684 7.20666 7.70683 7.16 7.62683C7.16 7.6135 7.14666 7.60016 7.14 7.58016C7.14 7.56016 7.13334 7.54684 7.13334 7.52683C7.13334 7.48684 7.14668 7.46016 7.17334 7.43349L7.35334 7.24683C7.40667 7.19349 7.45334 7.13349 7.48668 7.08016C7.52001 7.02683 7.53333 6.96683 7.53333 6.91349C7.53333 6.86683 7.52 6.82017 7.5 6.7735C7.48 6.72017 7.44666 6.66683 7.40666 6.60683L6.86001 5.84017C6.82001 5.78017 6.76666 5.74016 6.69999 5.70683C6.63999 5.68016 6.57333 5.66683 6.51333 5.66683L6.5 5.66016Z" fill="#27BDBE"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_138_3222">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    <p className="text-white text-sm">
                        +971 52 9745838
                    </p>
                </div>
            </div>
            {/* <div className={styles.desc}>
                <p className="text-white text-sm">
                    Free shipping in UAE starting from €89
                </p>
            </div> */}
            <div className={styles.lang}>
                <p className="text-white text-sm">
                    AR
                </p>
            </div>
        </div>
    )
}

export default TopHeader;